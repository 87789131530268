import Vue from 'vue';
import VueRouter from 'vue-router';
import IRoute from '@/router/IRoute';

Vue.use(VueRouter);

const routes: IRoute[] = [
    {
        path: '/',
        name: 'Hlavní stránka',
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/jak-to-funguje',
        name: 'Jak to funguje',
        component: () => import('@/views/HowItWorks.vue'),
    },
    {
        path: '/prislusenstvi',
        name: 'Příslušenství',
        component: () => import('@/views/Accessory.vue'),
    },
    {
        path: '/darkovy-poukaz',
        name: 'Dárkový poukaz',
        component: () => import('@/views/Voucher.vue'),
    },
    {
        path: '/kontakt',
        name: 'Kontakt',
        component: () => import('@/views/Contact.vue'),
    },
];

const motorcycleRoutes: IRoute[] = [
    {
        path: '/motocykl/harley-davidson-sportster-iron-883',
        name: 'Sportster Iron 883',
        component: () => import('@/views/motorcycle/SportsterIron883.vue'),
    },
    {
        path: '/motocykl/harley-davidson-sport-glide',
        name: 'Sport Glide',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/motorcycle/SportGlide.vue'),
    },
];

const errorRoutes: IRoute[] = [
    {
        path: '*',
        name: 'Stránka nenalezena',
        component: () => import('@/views/errorPages/E404.vue'),
    },
];

const routesToRegister = [...routes, ...motorcycleRoutes, ...errorRoutes];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routesToRegister,
});

export default router;
export const ConstMainRoutes = routes;
export const ConstMotorcycleRoutes = motorcycleRoutes;
