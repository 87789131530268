
import {Component, Vue} from 'vue-property-decorator';
import LinkItem from '@/components/Navigation/LinkItem.vue';
import {ConstMainRoutes, ConstMotorcycleRoutes} from '@/router/index';
import Social from '@/components/Navigation/Social.vue';

@Component<Navigation>({
    components: {
        Social,
        LinkItem,
    },
})
export default class Navigation extends Vue
{
    private isOpen: boolean = false;

    private mainRoutes?: object[];
    private motorcycleRoutes?: object[];

    public beforeCreate()
    {
        this.mainRoutes = ConstMainRoutes;
        this.motorcycleRoutes = ConstMotorcycleRoutes;
    }

    public menuItemClicked(event: MouseEvent)
    {
        this.isOpen = false;
    }

    public hamburgerClicked(event: MouseEvent)
    {
        this.isOpen = !this.isOpen;
    }
}
