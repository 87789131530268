
import {Component, Prop, Vue} from 'vue-property-decorator';
import IRoute from '@/router/IRoute';

@Component<LinkItem>({})
export default class LinkItem extends Vue
{
    @Prop({required: true})
    private route?: IRoute;
}
