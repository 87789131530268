
import {Component, Vue} from 'vue-property-decorator';
import Navigation from '@/components/Navigation.vue';

@Component({
    components: {
        Navigation,
    },
})
export default class App extends Vue {
    get isDevelopment() {
        // @ts-ignore
        return DEVELOPMENT;
    }
}
